import React from "react";
import Home from "./static/Home";
import About from "./static/About";
import GetApp from "./static/GetApp";
import Team from "./static/Team";

const Main = () => {
  return (
    <>
      <Home />
      <About />
      <GetApp />
      <Team />
    </>
  );
};

export default Main;
