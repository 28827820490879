import React from "react";

const SortModal = ({ closeModal, applySort, sortTerm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Sort By</h2>
        <ul className="list-none p-0">
          <li className="mb-4">
            <button
              className={`cursor-pointer max-w-[300px] w-full max-h-[50px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                sortTerm === "priceLowToHigh"
                  ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                  : "bg-white"
              }`}
              onClick={() => applySort("priceLowToHigh")}
            >
              <p className="text-md font-normal text-[#344054] text-center">
                Price - low to high
              </p>
            </button>
          </li>
          <li className="mb-4">
            <button
              className={`cursor-pointer max-w-[300px] w-full max-h-[50px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                sortTerm === "priceHighToLow"
                  ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                  : "bg-white"
              }`}
              onClick={() => applySort("priceHighToLow")}
            >
              <p className="text-md font-normal text-[#344054] text-center">
                Price - high to low
              </p>
            </button>
          </li>
          <li className="mb-4">
            <button
              className={`cursor-pointer max-w-[300px] w-full max-h-[50px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                sortTerm === "experienceHighToLow"
                  ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                  : "bg-white"
              }`}
              onClick={() => applySort("experienceHighToLow")}
            >
              <p className="text-md font-normal text-[#344054] text-center">
                Experience - high to low
              </p>
            </button>
          </li>
          <li className="mb-4">
            <button
              className={`cursor-pointer max-w-[300px] w-full max-h-[50px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                sortTerm === "ratingHighToLow"
                  ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                  : "bg-white"
              }`}
              onClick={() => applySort("ratingHighToLow")}
            >
              <p className="text-md font-normal text-[#344054] text-center">
                Rating - high to low
              </p>
            </button>
          </li>
        </ul>
        <div className="w-full flex justify-center item-center">
          <button
            onClick={() => closeModal(false)}
            className="mt-4 bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-full"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SortModal;
