import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import {
  ApprochesData,
  FocusData,
  LanguagesData,
  genderData,
} from "../../../constants/filtersData";

const FilterModal = ({ closeModal, applyFilters, clearFilters }) => {
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [genderChoose, setGenderChoose] = useState("");
  const [languages, setLanguages] = useState([]);
  const [areaOfFocus, setAreaOfFocus] = useState([]);
  const [selectedApproaches, setSelectedApproaches] = useState([]);

  const experienceOptions = [
    { range: "0-3", price: 1000 },
    { range: "4-6", price: 1600 },
    { range: "7-9", price: 2200 },
    { range: "10+", price: 2800 },
  ];

  const handleExperienceClick = (range, price) => {
    setSelectedExperience(range);
    setSelectedPrice(price);
  };

  const handleCheckboxChange = (selectedApproach) => {
    if (selectedApproaches.includes(selectedApproach)) {
      setSelectedApproaches((prevApproaches) =>
        prevApproaches.filter((approach) => approach !== selectedApproach)
      );
    } else {
      setSelectedApproaches((prevApproaches) => [
        ...prevApproaches,
        selectedApproach,
      ]);
    }
  };

  const handleAreaofFocus = (selectedItems) => {
    if (areaOfFocus.includes(selectedItems)) {
      setAreaOfFocus((prevItem) =>
        prevItem.filter((lang) => lang !== selectedItems)
      );
    } else {
      setAreaOfFocus((prevItem) => [...prevItem, selectedItems]);
    }
  };

  const handleLanguageClick = (selectedLanguage) => {
    if (languages.includes(selectedLanguage)) {
      setLanguages((prevLanguages) =>
        prevLanguages.filter((lang) => lang !== selectedLanguage)
      );
    } else {
      setLanguages((prevLanguages) => [...prevLanguages, selectedLanguage]);
    }
  };

  const handleApplyFilters = () => {
    let minYearsOfExperience = 0;
    let maxYearsOfExperience = 0;

    if (selectedExperience.includes("-")) {
      const [min, max] = selectedExperience.split("-").map(Number);
      minYearsOfExperience = min;
      maxYearsOfExperience = max;
    } else if (selectedExperience === "10+") {
      minYearsOfExperience = 10;
      maxYearsOfExperience = Infinity; // Or a high value representing "10+"
    }
    const filters = {
      genders: genderChoose,
      languages,
      concerns: areaOfFocus,
      minYearsOfExperience,
      maxYearsOfExperience,
      price: selectedPrice,
      approaches: selectedApproaches,
    };

    applyFilters(filters);
    closeModal(false);
  };

  const handleClearAll = () => {
    setSelectedExperience("");
    setSelectedPrice("");
    setGenderChoose("");
    setLanguages([]);
    setAreaOfFocus([]);
    setSelectedApproaches([]);
    clearFilters();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div className="fixed mx-2 inset-0 flex justify-center items-center z-10 ">
      <div className=" overflow-y-scroll fixed inset-0 bg-black opacity-60"></div>
      <div className="w-[350px] h-[600px] md:h-auto md:w-[800px] rounded-[12px] gap-3 bg-white flex flex-col p-[25px] relative  overflow-y-scroll md:overflow-y-hidden">
        <div className="flex w-full items-center">
          <div className="text-[#101828] text-lg font-medium w-full text-center">
            Filter
          </div>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-purple-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-purple-600 dark:hover:text-white"
            data-modal-hide="default-modal"
            onClick={() => closeModal(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <hr></hr>
        <div className="flex flex-col md:flex-row justify-center w-full gap-4 md:gap-12">
          <div className="flex flex-col gap-6 md:w-1/2">
            <div className="text-[#101828] font-semibold text-lg">
              Areas of focus
            </div>
            <div className="flex flex-wrap gap-1">
              {FocusData.map((item, idx) => (
                <div
                  key={idx}
                  className={`cursor-pointer mx-auto max-w-[110px] w-full max-h-[30px] h-full rounded-[16px] flex justify-center items-center py-[18px] px-[6px] border-[1px] ${
                    areaOfFocus.includes(item)
                      ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                      : "bg-white"
                  }`}
                  onClick={() => handleAreaofFocus(item)}
                >
                  <p className="text-xs font-normal text-[#344054] text-center">
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-6 md:w-1/2">
            <div className="text-[#101828] font-semibold text-lg">
              Languages
            </div>
            <div className="flex flex-wrap gap-1">
              {LanguagesData.map((item, idx) => (
                <div
                  key={idx}
                  className={`cursor-pointer mx-auto max-w-[80px] w-full max-h-[30px] h-full rounded-[16px] flex justify-center items-center py-[14px] px-[4px] border-[1px] ${
                    languages.includes(item)
                      ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                      : "bg-white"
                  }`}
                  onClick={() => handleLanguageClick(item)}
                >
                  <p className="text-xs font-normal text-[#344054] text-center">
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="flex flex-col md:flex-row justify-center w-full gap-4 md:gap-12">
          <div className="flex flex-col  gap-4 md:w-1/3">
            <div className="flex justify-between px-4 ">
              <div className="text-[#101828] font-semibold text-lg">
                Years of Experience
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              {experienceOptions.map((option, idx) => (
                <button
                  key={idx}
                  className={`cursor-pointer max-w-[100px] w-full max-h-[30px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                    selectedExperience === option.range
                      ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                      : "bg-white"
                  }`}
                  onClick={() =>
                    handleExperienceClick(option.range, option.price)
                  }
                >
                  <p className="text-xs font-normal text-[#344054] text-center">
                    {option.range} years
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-6 md:w-1/3">
            <div className="text-[#101828] font-semibold text-lg">Price</div>
            <div className="flex flex-wrap gap-2">
              {experienceOptions.map((option, idx) => (
                <button
                  key={idx}
                  className={`cursor-pointer max-w-[100px] w-full max-h-[30px] h-full rounded-[16px] flex justify-center items-center py-[8px] px-[12px] border-[1px] ${
                    selectedPrice === option.price
                      ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                      : "bg-white"
                  }`}
                  onClick={() =>
                    handleExperienceClick(option.range, option.price)
                  }
                >
                  <p className="text-xs font-normal text-[#344054] text-center">
                    Rs. {option.price}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-6 md:w-1/3">
            <div className="text-[#101828] font-semibold text-lg">Gender</div>
            <div className="flex flex-wrap gap-1">
              {genderData.map((item, idx) => (
                <div
                  key={idx}
                  className={`cursor-pointer max-w-[82px] w-full max-h-[30px] h-full rounded-[16px] flex justify-center items-center py-[12px] px-[4px] border-[1px] ${
                    genderChoose === item
                      ? "bg-[#ECE7FE] text-white border-[#4E139F]"
                      : "bg-white"
                  }`}
                  onClick={() => setGenderChoose(item)}
                >
                  <p className="text-xs font-normal text-[#344054] text-center">
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="flex flex-col gap-6 w-full">
          <div className="text-[#101828] font-semibold text-lg">Approach</div>
          <div className="flex flex-col gap-2 md:flex-row justify-between">
            {ApprochesData.map((item, idx) => (
              <div key={idx} className="flex justify-between gap-2">
                <label>{item}</label>
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  onChange={() => handleCheckboxChange(item)}
                  checked={selectedApproaches.includes(item)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center w-full items-center">
          <button
            onClick={() => handleClearAll()}
            className="my-4 bg-gray-300 text-black px-12 py-2 rounded-full mr-2"
          >
            Clear All
          </button>
          <button
            onClick={() => handleApplyFilters()}
            className="my-4 bg-primaryIndigo hover:bg-purple-600 text-white px-12 py-2 rounded-full mr-2"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
