import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFilter,
  faStar,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import FilterModal from "./FilterModal";
import therapistService from "../../../services/therapistService";
import SortModal from "./SortModal";

const Therapist = () => {
  const navigate = useNavigate();
  const [filteredTherapists, setFilteredTherapists] = useState([]);
  const [therapistData, setTherapistData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const applySort = (sortType) => {
    setSortTerm(sortType);
    let sortedData = [];
    switch (sortType) {
      case "priceLowToHigh":
        sortedData = [...filteredTherapists].sort((a, b) => a.price - b.price);
        break;
      case "priceHighToLow":
        sortedData = [...filteredTherapists].sort((a, b) => b.price - a.price);
        break;
      case "experienceHighToLow":
        sortedData = [...filteredTherapists].sort(
          (a, b) => b.yearsOfExperience - a.yearsOfExperience
        );
        break;
      case "ratingHighToLow":
        sortedData = [...filteredTherapists].sort(
          (a, b) => b.rating - a.rating
        );
        break;
      default:
        sortedData = [...filteredTherapists];
    }
    setFilteredTherapists(sortedData);
    setOpenSortModal(false);
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await therapistService.getAllThearapists();
        if (response.data.success) {
          const therapistsData = response.data.data.map((item) => {
            try {
              const fixedJsonString = item?.concernSpecialisation.replace(
                /\\"/g,
                '"'
              );
              // Calculate rangeOfExperience and price based on yearsOfExperience
              if (item.yearsOfExperience !== undefined) {
                if (
                  item.yearsOfExperience >= 0 &&
                  item.yearsOfExperience <= 3
                ) {
                  item.price = 1000;
                } else if (
                  item.yearsOfExperience >= 4 &&
                  item.yearsOfExperience <= 6
                ) {
                  item.price = 1600;
                } else if (
                  item.yearsOfExperience >= 7 &&
                  item.yearsOfExperience <= 9
                ) {
                  item.price = 2200;
                } else if (item.yearsOfExperience >= 10) {
                  item.price = 2800;
                }
              }
              return {
                ...item,
                concernSpecialisation: JSON.parse(fixedJsonString),
              };
            } catch (error) {
              console.error(
                `Error parsing JSON for therapist with ID ${item?.therapistId}:`,
                error
              );
              return null;
            }
          });
          setFilteredTherapists(therapistsData);
          setTherapistData(therapistsData);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const applyFilters = async (filters) => {
    try {
      const response = await therapistService.getFilteredTherapists(filters);
      if (response.data.success) {
        const therapistsData = response.data.data.map((item) => {
          try {
            const fixedJsonString = item?.concernSpecialisation.replace(
              /\\"/g,
              '"'
            );
            // Calculate rangeOfExperience and price based on yearsOfExperience
            if (item.yearsOfExperience !== undefined) {
              if (item.yearsOfExperience >= 0 && item.yearsOfExperience <= 3) {
                item.price = 1000;
              } else if (
                item.yearsOfExperience >= 4 &&
                item.yearsOfExperience <= 6
              ) {
                item.price = 1600;
              } else if (
                item.yearsOfExperience >= 7 &&
                item.yearsOfExperience <= 9
              ) {
                item.price = 2200;
              } else if (item.yearsOfExperience >= 10) {
                item.price = 2800;
              }
            }
            return {
              ...item,
              concernSpecialisation: JSON.parse(fixedJsonString),
            };
          } catch (error) {
            console.error(
              `Error parsing JSON for therapist with ID ${item?.therapistId}:`,
              error
            );
            return null;
          }
        });
        setFilteredTherapists(therapistsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setFilteredTherapists(therapistData);
    } else {
      const filtered = therapistData.filter((therapist) =>
        String(therapist.name).toLowerCase().includes(term.toLowerCase())
      );
      setFilteredTherapists(filtered);
    }
  };

  return (
    <div className="mx-auto p-3 max-w-screen-xl h-full flex flex-col gap-2 justify-center md:gap-2 mx-auto items-center">
      <div className="p-3 flex flex-row gap-4 w-full md:w-1/4 md:mb-2 md:pb-2">
        <div className="relative">
          <input
            placeholder="Search Therapist"
            className="relative border w-[200px] md:w-full px-10 py-3 rounded-[32px] border-black"
            value={searchTerm}
            onChange={handleInputChange}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-[10px] top-[16px] text-gray-500"
          />
        </div>
        <div
          onClick={() => setOpenModal(true)}
          className="cursor-pointer relative flex justify-center flex-col gap-1 border-primaryIndigo border px-4 py-1 rounded-full"
        >
          <FontAwesomeIcon icon={faFilter} className="text-primaryIndigo" />
        </div>
        <div
          onClick={() => setOpenSortModal(true)}
          className="cursor-pointer relative flex justify-center flex-col gap-1 border-primaryIndigo border px-4 py-1 rounded-full"
        >
          <FontAwesomeIcon icon={faSort} className="text-primaryIndigo" />
        </div>
        {openModal && (
          <FilterModal
            closeModal={setOpenModal}
            applyFilters={applyFilters}
            clearFilters={() => {
              setFilteredTherapists(therapistData);
              setOpenModal(false);
            }}
          />
        )}
        {openSortModal && (
          <SortModal
            sortTerm={sortTerm}
            closeModal={setOpenSortModal}
            applySort={applySort}
          />
        )}
      </div>
      <div className="overflow-auto  flex p-3 flex-row gap-8 md:gap-4 justify-center xl:justify-center flex-wrap w-full">
        {filteredTherapists.length > 0 &&
          filteredTherapists.map((item, idx) => (
            <div
              key={idx}
              className="w-full bg-[#F7F7F7] shadow-xl rounded-2xl overflow-hidden"
            >
              <div className="flex flex-col">
                <div className="py-8 flex flex-col md:flex-row justify-center items-center gap-6 md:gap-12 max-w-1/2 w-full h-5/6 p-4">
                  <img
                    className="w-40 h-40 object-cover rounded-xl"
                    src={item?.imgUrl}
                    alt="Therapist Photo"
                  />
                  <div className="max-w-1/2 w-full flex flex-col justify-center gap-1">
                    <div className="flex justify-between items-center">
                      <div className="px-2 py-4 rounded-[16px] flex justify-center items-center  bg-[#F0F0FE] border-[1px] border-[##EAECF0]">
                        <p className="text-xs font-semibold text-[#4E139F] text-center ">
                          {item?.yearsOfExperience}+ years of experience
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-yellow-400"
                        />
                        <p className="text-[#475467] text-base font-normal">
                          {item?.rating}
                        </p>
                      </div>
                    </div>
                    <p className="text-[#101828] text-xl font-bold mb-4">
                      {item?.name}
                    </p>
                    <p className="text-[#475467] text-base font-normal">
                      <span className="font-semibold">
                        Rs. {item?.price}/hr
                      </span>
                    </p>
                    <div className="flex items-center w-full gap-2">
                      <p className="text-[#475467] text-base font-normal w-1/7">
                        Best help for:
                      </p>
                      <div className="flex flex-wrap gap-2 w-6/7">
                        {item?.concernSpecialisation
                          .slice(0, 5)
                          .map((specialization, index) => (
                            <div key={index}>
                              <p className="text-lg font-semibold text-[#344054] text-center">
                                {specialization}
                                {index !== 4 && ","}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-2 pb-8">
                  <button
                    className="my-4 bg-primaryIndigo hover:bg-blue-600 text-white px-4 py-2 rounded-full mr-2"
                    onClick={() =>
                      navigate(`/book-session/${item?.therapistId}`)
                    }
                  >
                    Book a Session
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/view-details/${item?.therapistId}`)
                    }
                    className="my-4 bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-full"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Therapist;
